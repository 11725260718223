import { FuseLoadable } from '@fuse';
import Authorize from '../../Authorize';

export const layoutConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: '/trade',
      component: Authorize(FuseLoadable({
        loader: () => import('./Layout')
      })),
    },
    {
      path: '/embed',
      component: FuseLoadable({
        loader: () => import('./Embed')
      }),
    },
    {
      path: '/market_summary',
      component: FuseLoadable({
        loader: () => import('./EmbedMarketSummary')
      }),
    },
    {
      path: '/isin_form/:type?',
      component: FuseLoadable({
        loader: () => import('./formTest')
      }),
    },
    {
      path: '/broker_embed',
      component: FuseLoadable({
        loader: () => import('./Broker_Embed')
      }),
    }
  ]
};
