import {API_URL, API, BROKERID} from '../../config';

/* Define Actions */
export const SET_BUSY = '[COMPANY] SET BUSY';
export const SET_HISTORY_LOADING = '[COMPANY] SET HISTORY LOADING';
export const SET_COMPANIES_INFO = '[COMPANY] LOAD COMPANIES';
export const SELECT_COMPANY = '[COMPANY] SELECT COMPANY';
export const SET_COMPANY_HISTORY = '[COMPANY] LOAD COMPANY HISTORY';
export const SET_QUERY = '[COMPANY] SET QUERY';
export const ADD_REMOVE_WATCH_LIST = '[COMPANY] ADD REMOVE WATCH LIST';
export const SET_WATCHLIST = '[COMPANY] SET WATCHLIST';
export const ADD_TRADE = '[COMPANY] ADD TRADE';

export const setBusy = () => ({
  type: SET_BUSY,
});
export const setHistoryLoading = () => ({
  type: SET_HISTORY_LOADING,
});
export const setCompaniesInfo = (info) => ({
  type: SET_COMPANIES_INFO,
  info,
});
export const selectCompany = (id) => ({
  type: SELECT_COMPANY,
  id,
});
export const setCompanyHistory = (id, history) => ({
  type: SET_COMPANY_HISTORY,
  id,
  history,
});
export const setQuery = (query) => ({
  type: SET_QUERY,
  query,
});
export const addTrade = (atype) => ({
  type: ADD_TRADE,
  atype,
})
export const addRemoveToWatchList = (ftype, payload) => ({
  type: ADD_REMOVE_WATCH_LIST,
  ftype,
  payload
});
export const setWatchList = (followed) => ({
  type: SET_WATCHLIST,
  followed
})

const followers = API.service('followers');

export const addRemoveWatchList = (ftype, payload) => {
  return (dispatch) => {
    if (ftype === 'create') {
      followers.create(payload).then((f) => {
        dispatch(addRemoveToWatchList(ftype, payload));
      })
    }
    if (ftype === 'patch') {
      followers.remove(null, { query: { _my_account: payload._my_account, _account_to_follow: payload._account_to_follow } }).then((f) => {
        dispatch(addRemoveToWatchList(ftype, payload));
      })
    }
  }
}

export const fetchCompaniesInfo = (id) => {
  return (dispatch, getState) => {
    dispatch(setBusy);
    fetch(API_URL + '/companies?$sort[name]=1&status[$not]=99&broker_exclusive[$in]=0&broker_exclusive[$in]=' + BROKERID) //all instruments are retrieved excluding 99 (Unlisted)
      .then(
        response => response.json(),
        error => console.log('An error occurred.', error),
      )
      .then((info) => {
        if (info === undefined) return;

        dispatch(setCompaniesInfo(info));
        if (info.length) {
          let selectedCompany = getState().company.selectedCompany
          dispatch(fetchCompanyHistory(selectedCompany || info[0].id));
        }
      });
  }
};

export const fetchCompanyHistory = (id) => {
  return (dispatch) => {
    dispatch(setHistoryLoading);
    dispatch(selectCompany(id))
    fetch(API_URL + `/history?companyId=${id}`)
      .then(
        res => res.json(),
        error => console.log('An error occurred.', error),
      )
      .then((history) => {
        dispatch(setCompanyHistory(id, history));
      });
  }
}

export const getWatchList = (account, type) => {
  return (dispatch) => {
    followers.find({ query: { _my_account: account, _follow_type: type } }).then(fc => {
      let followed = [];
      if (fc.total > 0) {
        fc.data.forEach((v, i) => {
          followed.push(v._account_to_follow);
        })
      }
      dispatch(setWatchList(followed));
    });
  }
}