import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEn from "./language/en/translation.json";
import translationzh_CN from "./language/zh_CN/translation.json";
import translationNl from "./language/nl/translation.json";
//import {ADDITIONAL_LANGUAGE_FILE} from "./config";

let ADDITIONAL_LANGUAGE_FILE = (process.env.REACT_APP_ADDITIONAL_LANGUAGE_FILE) ? process.env.REACT_APP_ADDITIONAL_LANGUAGE_FILE : null;

// eslint-disable-next-line
let addTranslationEn = {}
// eslint-disable-next-line
let addTranslationzh_CN = {}
// eslint-disable-next-line
let addTranslationNl = {}

let en ={ };
let cn ={ };
let nl ={ };

if(ADDITIONAL_LANGUAGE_FILE !== null){
    let addTranslationEn = require("./language/en/" + ADDITIONAL_LANGUAGE_FILE);
    en = {...translationEn, ...addTranslationEn};
    let addTranslationzh_CN = require("./language/zh_CN/" + ADDITIONAL_LANGUAGE_FILE);
    cn = {...translationzh_CN, ...addTranslationzh_CN};
    let addTranslationNl = require("./language/nl/" + ADDITIONAL_LANGUAGE_FILE);
    nl = {...translationNl, ...addTranslationNl};
} else {
    en = translationEn;
    cn = translationzh_CN;
    nl = translationNl;
}


i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    resources: {
      en: {
        translations: en,
      },
      zh_CN:{
        translations: cn
      },
      nl:{
        translations: nl
      }
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations"
  });

export default i18n;
